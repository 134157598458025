import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { ApolloConsumer } from 'react-apollo';
import { get } from 'lodash';
import ProfilePicture from '../ProfilePicture/ProfilePicture';
import UserRatingContainer from '../../containers/UserRatingContainer';
import AddToRolodexButton from '../AddToRolodexButton/AddToRolodexButton';
import RemoveFromRolodexButton from '../RemoveFromRolodexButton/RemoveFromRolodexButton';
import Headline from '../Headline/Headline';
import UserName from './BobCardComponents/UserName';
import UserLocation from './BobCardComponents/UserLocation';
import BobCardMessageButton from './BobCardComponents/BobCardMessageButton';
import BobCardUserDetails from './BobCardComponents/BobCardUserDetails';
import { UserProfileQueries } from '../../containers/UserProfileContainer';
import MessageIconButton from './BobCardComponents/MessageIconButton';

const getCityCounty = (city, county, countryForCompany, companyHasCounty, show_city) => {
  if (countryForCompany === 'us' && companyHasCounty) {
    if (show_city) {
      return city;
    }
    return county;
  }
  return city;
};

const tagFor = (user, countryForCompany, companyHasCounty, currentUserHasLimitedAccess) => {
  let tags = [];
  if (user.obfuscated || (!user.is_pure_buyer && currentUserHasLimitedAccess)) {
    tags = [
      <FormattedMessage
        id="bobcard.avatar_tag.in_revenue"
        values={{ revenue: <span className="blurry-text">00000</span> }}
      />,
      <FormattedMessage
        id="bobcard.avatar_tag.clients"
        values={{ clients: <span className="blurry-text">00000</span> }}
      />,
    ];
  } else {
    tags = [
      getCityCounty(user.city, user.county, countryForCompany, companyHasCounty, user.show_city) || (
        <FormattedMessage id="bobcard.avatar_tag.quality_practice" />
      ),
      <FormattedMessage id="bobcard.avatar_tag.quality_practice" />,
      <FormattedMessage id="bobcard.avatar_tag.worth_a_look" />,
      <FormattedMessage id="bobcard.avatar_tag.growth_potential" />,
      <FormattedMessage id="bobcard.avatar_tag.in_revenue" values={{ revenue: user.annual_revenue }} />,
      <FormattedMessage id="bobcard.avatar_tag.clients" values={{ clients: user.number_of_clients }} />,
    ];
  }
  return tags[Math.floor(Math.random() * tags.length)];
};

const clickedHeadline = async (client, user, updateSelectedProfileDrawer, anchorType) => {
  const result = await client.query({
    query       : UserProfileQueries,
    variables   : { userName: user.fb_user_name },
    fetchPolicy : 'network-only',
  });
  updateSelectedProfileDrawer({ ...get(result, 'data'), anchor: anchorType });
};

const BobCard = ({
  user,
  isUserAuthorized,
  displaySocialButtons,
  canDeleteNotes,
  refreshList,
  countryForCompany,
  companyHasCounty,
  updateBobCards,
  loadBobCardList,
  userIsAdmin,
  currentUserHasLimitedAccess,
  currentCompanySellerWord,
  currentUserHasSellingTransitionGoals,
  getCurrentUser,
  disableMessageBtn,
  marketplaceOnly,
  updateSelectedProfileDrawer,
  updateNotes,
  refetch,
  companyBusinessType,
  sortedUsers,
  removeFromList,
}) => {
  return (
    <div className="content-card">
      <ApolloConsumer>
        {client => (
          <div
            onClick={e => {
              if (e && e._dispatchListeners.length == 1) {
                clickedHeadline(client, user, updateSelectedProfileDrawer);
              }
            }}
            className="property"
            style={{ marginBottom: `${marketplaceOnly ? 10 : ''}` }}>
            <figure className="tag status">
              {tagFor(user, countryForCompany, companyHasCounty, currentUserHasLimitedAccess, marketplaceOnly)}
            </figure>
            <div className="property-image" style={{ height: 'auto' }}>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  clickedHeadline(client, user, updateSelectedProfileDrawer);
                }}>
                <ProfilePicture pictureUrl={user.profile_picture} userName={user.user_name} userIsStudent={user.is_student} userCompanyEnabledStudentProfiles={user.company.enabled_student_profiles} disableClick userCompanyStudentWord={user.company.student_word}/>
              </a>
              <UserRatingContainer
                marketplaceOnly={marketplaceOnly}
                userId={parseInt(user.id)}
                score={user.my_rating}
                user={user}
                updateBobCards={updateBobCards}
                loadBobCardList={loadBobCardList}
                refetch={refetch}
              />
            </div>
            <div className="info">
              <header>
                <figure className="alignleft">
                  <UserName user={user} />
                </figure>
                <figure className="alignright">
                  &nbsp;
                  <AddToRolodexButton
                    user={user}
                    userId={user.id}
                    userName={user.fb_user_name}
                    display={displaySocialButtons}
                    noteCount={user.note_count}
                    updateBobCards={updateBobCards}
                    loadBobCardList={loadBobCardList}
                    fromBobCard
                    marketplaceOnly={marketplaceOnly}
                    clickAction={() => {
                      clickedHeadline(client, user, updateSelectedProfileDrawer, 'notebook');
                    }}
                    updateNotes={updateNotes}
                  />
                  {canDeleteNotes && (
                    <RemoveFromRolodexButton
                      onClick={refreshList}
                      user={user}
                      updateBobCards={updateBobCards}
                      loadBobCardList={loadBobCardList}
                      refetch={refetch}
                      sortedUsers={sortedUsers}
                      removeFromList={removeFromList}
                    />
                  )}
                  {!disableMessageBtn && (
                    <MessageIconButton
                      hasMessages={user.conversation_with_me}
                      onClick={() => {
                        clickedHeadline(client, user, updateSelectedProfileDrawer, 'chat');
                      }}
                    />
                  )}
                </figure>
                <h3 className="clear" style={{ cursor: 'pointer' }}>
                  <Headline
                    user={user}
                    userIsAdmin={userIsAdmin}
                    currentUserHasLimitedAccess={currentUserHasLimitedAccess}
                    currentCompanySellerWord={currentCompanySellerWord}
                    handleClick={() => clickedHeadline(client, user, updateSelectedProfileDrawer)}
                  />
                </h3>
                <figure>
                  <UserLocation user={user} countryForCompany={countryForCompany} companyHasCounty={companyHasCounty} />
                </figure>
              </header>
              <div className="clear">
                <div>
                  <BobCardMessageButton
                    user={user}
                    currentUserHasLimitedAccess={currentUserHasLimitedAccess}
                    hasInterestedUsers={user.expressed_interest}
                    updateBobCards={updateBobCards}
                    loadBobCardList={loadBobCardList}
                    currentCompanySellerWord={currentCompanySellerWord}
                    currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
                    getCurrentUser={getCurrentUser}
                    disableMessageBtn={disableMessageBtn}
                    marketplaceOnly={marketplaceOnly}
                  />
                </div>
                <BobCardUserDetails
                  user={user}
                  isUserAuthorized={isUserAuthorized}
                  userIsAdmin={userIsAdmin}
                  currentUserHasLimitedAccess={currentUserHasLimitedAccess}
                  currentUserHasSellingTransitionGoals={currentUserHasSellingTransitionGoals}
                  marketplaceOnly={marketplaceOnly}
                  handleClick={() => clickedHeadline(client, user, updateSelectedProfileDrawer)}
                  companyBusinessType={companyBusinessType}
                />
              </div>
            </div>
          </div>
        )}
      </ApolloConsumer>
    </div>
  );
};

BobCard.propTypes = {
  user                                 : PropTypes.shape({
    annual_revenue          : PropTypes.number,
    assets_under_management : PropTypes.number,
    bio                     : PropTypes.string,
    city                    : PropTypes.string,
    id                      : PropTypes.string,
    number_of_clients       : PropTypes.number,
    province                : PropTypes.string,
    recurring_revenue       : PropTypes.number,
    user_name               : PropTypes.string,
    headline                : PropTypes.string,
    refreshList             : PropTypes.func,
    show_city               : PropTypes.bool,
    obfuscated              : PropTypes.bool,
  }).isRequired,
  isUserAuthorized                     : PropTypes.bool.isRequired,
  canDeleteNotes                       : PropTypes.bool.isRequired,
  displaySocialButtons                 : PropTypes.bool.isRequired,
  companyHasCounty                     : PropTypes.bool.isRequired,
  countryForCompany                    : PropTypes.string.isRequired,
  refreshList                          : PropTypes.func,
  updateBobCards                       : PropTypes.func,
  loadBobCardList                      : PropTypes.func.isRequired,
  updateSelectedProfileDrawer          : PropTypes.func,
  getCurrentUser                       : PropTypes.object.isRequired,
  userIsAdmin                          : PropTypes.bool.isRequired,
  currentUserHasSellingTransitionGoals : PropTypes.bool.isRequired,
  currentUserHasLimitedAccess          : PropTypes.bool.isRequired,
  disableMessageBtn                    : PropTypes.bool.isRequired,
  marketplaceOnly                      : PropTypes.bool.isRequired,
  currentCompanySellerWord             : PropTypes.string.isRequired,
  updateNotes                          : PropTypes.func.isRequired,
  refetch                              : PropTypes.func.isRequired,
  companyBusinessType                  : PropTypes.string.isRequired,
  sortedUsers                          : PropTypes.array,
};

BobCard.defaultProps = {
  refreshList                 : () => {},
  updateSelectedProfileDrawer : () => {},
  updateBobCards              : () => {},
  sortedUsers                 : [],
};

export default BobCard;
