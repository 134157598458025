import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { get, truncate } from 'lodash';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import { S3Image } from '../../lib/s3';
import { injectIntl, FormattedMessage } from 'react-intl';

const styles = theme => ({
  root           : {
    width    : '100%',
    maxWidth : 360,
    height   : 'calc(100vh - 200px)',
    overflow : 'scroll',
  },
  inline         : {
    display : 'inline',
  },
  selected       : {
    background : '#dadada',
  },
  standaloneList : {
    overflowY : 'scroll',
  },
});

function ConverastionList (props) {
  const { classes, conversations, currentUser, room_id, updateParent, intl } = props;
  return (
    <List className={classes.root}>
      <h2>&nbsp;<FormattedMessage id="messages_section.conversations"/></h2>
      {conversations.map(convo => {
        let otherUser = null;
        if (currentUser.id === get(convo, 'participant1.id')) {
          otherUser = get(convo, 'participant2');
        } else {
          otherUser = get(convo, 'participant1');
        }
        const convo_type = get(convo, 'conversationType');
        let subtitle = convo_type;
        if (convo_type === 'deal') {
          const deal_name = get(convo, 'deal.deal_name');
          subtitle = `Deal: ${deal_name}`;
        } else if (convo_type === 'deal_interest') {
          subtitle = intl.formatMessage({ id: "messages_section.deal_interest"});
        } else if (convo_type === 'partial_sale_listing') {
          subtitle = `${get(currentUser, 'company.partial_sale_name')} Listing`;
        }
        const convoTitle = () => {
          if (get(otherUser, 'hidden')) {
            if (convo_type === 'deal_interest') {
              return `Deal Room closed: (${subtitle})`;
            } else {
              return intl.formatMessage({ id: 'messages_section.user_no_longer_active', subtitle});
            }
          }
          return `${convo_type === 'deal_interest'
            ? get(otherUser, 'allowed_name', '')
            : get(otherUser, 'user_name', '') || 'Deleted User'} : (${subtitle})`;
        };
        return (
          <ListItem
            alignItems="flex-start"
            onClick={e =>
              updateParent({
                room_id       : convo.id,
                title         : `${convo_type === 'deal_interest'
                  ? get(otherUser, 'allowed_name', '')
                  : get(otherUser, 'user_name', '')}`,
                open          : false,
                can_view_name : get(otherUser, 'can_view_real_name', false),
                otherUser,
              })}
            className={`${room_id}` === `${convo.id}` ? classes.selected : ''}
            button>
            <ListItemAvatar>
              <Avatar alt="" src={S3Image(get(otherUser, 'profile_picture'))} />
            </ListItemAvatar>
            <ListItemText
              primary={convoTitle()}
              secondary={
                <React.Fragment>
                  <span className={classes.inline} color="textPrimary">
                    {convo_type === 'deal_interest' ||
                    get(convo, 'lastMessages[0].sender.user_name') === currentUser.user_name ? (
                      get(convo, 'lastMessages[0].sender.allowed_name')
                    ) : (
                      get(convo, 'lastMessages[0].sender.user_name')
                    )}&nbsp;:&nbsp;
                  </span>
                  {truncate(get(convo, 'lastMessages[0].body', ''), {
                    length    : 24,
                    separator : ' ',
                  })}
                </React.Fragment>
              }
            />
          </ListItem>
        );
      })}
    </List>
  );
}

ConverastionList.propTypes = {
  classes       : PropTypes.object.isRequired,
  conversations : PropTypes.object.isRequired,
  currentUser   : PropTypes.object.isRequired,
  room_id       : PropTypes.number.isRequired,
  updateParent  : PropTypes.func.isRequired,
};

export default injectIntl(withStyles(styles)(ConverastionList));
