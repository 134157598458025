import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import { S3Image } from '../../lib/s3';
import initials from 'initials';
import { bgColorForName } from '../../lib/findBobUtils';
import moment from 'moment';
import {injectIntl} from 'react-intl'
import {get} from 'lodash';

export const getProfileImage = (message) => {
  if(get(message,'sender.id') == null && message.chat_type == 'paddy') {
    return "paddy.png";
  }
  return get(message, 'sender.profile_picture');
}

export const Message = ({ message, classes, currentUser, dealInterestStage, canViewRealName, intl }) => {
  
  const showRealName = canViewRealName || dealInterestStage === 'negotiating';
  let name = showRealName ? get(message, 'sender.name', '') : get(message, 'sender.user_name', '');

  if (parseInt(currentUser.id, 10) === get(message, 'sender.id', -1)) {
    name = intl.formatMessage({id: 'messages_section.me_word'});
  }
  const profile_picture = getProfileImage(message);
  
  const sent = message.sent;
  return (
    <div className={classes.messageContainer}>
      <Avatar
        size="18"
        name={name}
        src={profile_picture ? S3Image(profile_picture) : null}
        style={{ backgroundColor: bgColorForName(name) }}>
        <span>{initials(name)}</span>
      </Avatar>
      <div className={classes.message}>
        <span className={classes.name}>{name}</span> &middot;{' '}
        <span className="#dadada">{intl.locale == 'fr' ? moment(sent).format('Do MMM YYYY, H:mm') : moment(sent).format('MMM Do YYYY, h:mm a')}</span>
        {Boolean(message.read) && <span className={classes.check}>&nbsp;&#10004;</span>}
        <br />

        {message.chat_type == 'paddy' || message.sender.user_name == 'Paddy'?
           <span className={classes.messageFormat} dangerouslySetInnerHTML={{__html: message.content}} />
           :
           <span className={classes.messageFormat}>{message.content}</span>
        }
        
      </div>
    </div>
  );
};

Message.propTypes = {
  dealInterestStage : PropTypes.string,
};

Message.defaultProps = {
  dealInterestStage : '',
};

const styles = {
  messageContainer : {
    display       : 'flex',
    flexDirection : 'row',
  },
  header           : {
    display : 'flex',
  },
  name             : {
    fontWeight : 'bold',
  },
  date             : {
    color : '#dadada',
  },
  message          : {
    padding    : '0 8px',
    whiteSpace : 'pre-wrap',
  },
  check            : {
    color : '#1396e2',
  },
  messageFormat    : {
    wordWrap : 'anywhere',
  },
};

export default injectIntl(withStyles(styles)(Message));
