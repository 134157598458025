import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import ClickActivities from './ClickActivities';
import SearchActivities from './SearchActivities';
import OtherActivities from './OtherActivities';
import MessageInfoBox from './MessageInfoBox';

class ActivityInfoBox extends React.Component {
  getActivity = (firstName, event) => {
    if (event) {
      switch (event.name) {
        case '$click':
          return <ClickActivities firstName={firstName} event={event} />;
        case 'Map Search':
        case 'List Search':
          return <SearchActivities firstName={firstName} event={event} />;
        case 'Message Sent':
          return (
            <div>
              <MessageInfoBox firstName={firstName} event={event} />
            </div>
          );
        default:
          return <OtherActivities firstName={firstName} event={event} />;
      }
    }
    return <div />;
  };

  render () {
    const { classes, firstName, initials, event, date, note, activity } = this.props;
    return (
      <Card className={classes.card}>
        <Grid container spacing={6}>
          <Grid item xs={2}>
            <Avatar className={classes.avatar}>{initials}</Avatar>
          </Grid>
          <Grid item xs={10}>
            <Typography className={classes.title}>{this.getActivity(firstName, event)}</Typography>
            {activity && <Typography className={classes.title}>{activity}</Typography>}
            <Typography className={classes.content} type="headline">
              {date}
            </Typography>
            <Typography className={classes.note} type="headline">
              {note}
            </Typography>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

ActivityInfoBox.propTypes = {
  classes   : PropTypes.object.isRequired,
  event     : PropTypes.object.isRequired,
  firstName : PropTypes.string.isRequired,
  initials  : PropTypes.string.isRequired,
  date      : PropTypes.string.isRequired,
  note      : PropTypes.string.isRequired,
  activity  : PropTypes.string.isRequired,
};

const styles = {
  card     : {
    borderRadius : 0,
    height       : 'auto',
    marginBottom : 1,
  },
  title    : {
    fontWeight : 'bold',
    textAlign  : 'left',
    marginTop  : 15,
    fontSize   : 18,
  },
  activity : {
    textAlign : 'left',
    marginTop : 15,
    fontSize  : 18,
  },
  content  : {
    textAlign : 'left',
    fontSize  : 16,
    color     : '#808080',
  },
  note     : {
    textAlign    : 'left',
    fontSize     : 16,
    color        : '#000',
    marginTop    : 8,
    marginBottom : 10,
    paddingRight : 60,
    overflowWrap : 'break-word',
  },
  avatar   : {
    margin   : 20,
    fontSize : 25,
  },
};

export default withStyles(styles)(ActivityInfoBox);
