import React from 'react';
import { Route, Switch } from 'react-router';
import CompanyListContainer from '../containers/CompanyListContainer';
import CompanyFormContainer from '../containers/CompanyFormContainer';
import InventoryUsersContainer from '../containers/InventoryUsersContainer';
import UserAdminContainer from '../containers/UserAdminContainer';
import DesignationsContainer from '../containers/DesignationsContainer';
import AdvisorLicensesContainer from '../containers/AdvisorLicensesContainer';
import InsurersContainer from '../containers/InsurersContainer';
import LinesOfBusinessesContainer from '../containers/LinesOfBusinessesContainer';
import PlansContainer from '../containers/PlansContainer';
import ConversationsContainer from '../containers/ConversationsAdminContainer';
import TodoListsContainer from '../containers/TodoListsContainer';
import TodoItemsContainer from '../containers/TodoItemsContainer';
import TodoItemQuestionsContainer from '../containers/TodoItemQuestionsContainer';
import PendingAuthorizedUsersContainer from '../containers/PendingAuthorizedUsersContainer';
import ReportsContainer from '../containers/Reports/ReportsContainer';
import OpportunitiyListAdminContainer from '../containers/OpportunityListAdminContainer';
import TalentSelectionContainer from '../containers/NewOpportunity/TalentSelectionContainer';
import NewOpportunityContainer from '../containers/NewOpportunity/NewOpportunityContainer';
import NewOpportunityDetailsContainer from '../containers/NewOpportunity/NewOpportunityDetailsContainer';
import EditOpportunityContainer from '../containers/EditOpportunityContainer';
import OpportunityStatContainer from '../containers/OpportunityStatContainer';
import PipelineContainer from '../containers/PipelineContainer';
import PipelineListContainer from '../containers/PipelineListContainer';
import ActivityTypeContainer from '../containers/ActivityTypeContainer';
import EducationalContentContainer from '../containers/EducationalContentContainer';
import TaskContainer from '../containers/TaskContainer';
import CalibrationSessionsContainer from '../containers/CalibrationSessionsContainer';
import CalibrationSessionContainer from '../containers/CalibrationSessionContainer';
import RecentActiveUsersContainer from '../containers/RecentActiveUsersContainer';
import DealsContainer from '../containers/DealsContainer';
import MatchPreference from '../components/MatchPreference/MatchPreference';
import DealContainer from '../containers/DealContainer';
import CompanyValuationContainer from '../containers/CompanyValuationContainer';
import LoanCalculatorContainer from '../containers/LoanCalculatorContainer';
import HomeOfficeCtaContainer from '../containers/HomeOfficeCtaContainer';
import CompanyESignatureContainer from '../containers/CompanyESignatureContainer';
import SuggestionsContainer from '../containers/SuggestionsContainer';
import SuggestedDealsContainer from '../containers/SuggestedDealsContainer';
import PayoutGridContainer from '../containers/PayoutGridContainer';
import TickersContainer from '../containers/TickersContainer';
import CalibrationSessionPlaceholder from '../components/CalibrationSessions/CalibrationSessionPlaceholder';
import NewCalibrationSession from "../components/CalibrationSessions/NewCalibrationSession";
import OnboardingBannerWidgetsContainer from "../containers/OnboardingBannerWidgetsContainer";
import AffiliationsContainer from '../containers/AffiliationsContainer';

export default (
  <Switch>
    <Route path="/admin/companies/e_sig_config" component={CompanyESignatureContainer} />
    <Route path="/admin/companies/valuation" component={CompanyValuationContainer} />
    <Route path="/admin/companies/loan_calculator" component={LoanCalculatorContainer} />
    <Route path="/admin/companies/home_office_cta" component={HomeOfficeCtaContainer} />
    <Route path="/admin/companies/:id" component={CompanyFormContainer} />
    <Route path="/admin/companies" component={CompanyListContainer} />
    <Route path="/admin/inventory_users" component={InventoryUsersContainer} />
    <Route path="/admin/user_admin" component={UserAdminContainer} />
    <Route path="/admin/designations" component={DesignationsContainer} />
    <Route path="/admin/advisor_licenses" component={AdvisorLicensesContainer} />
    <Route path="/admin/insurers" component={InsurersContainer} />
    <Route path="/admin/lines_of_businesses" component={LinesOfBusinessesContainer} />
    <Route path="/admin/plans" component={PlansContainer} />
    <Route path="/admin/conversations" component={ConversationsContainer} />
    <Route path="/admin/reports/:reportName" component={ReportsContainer} />
    <Route path="/admin/reports" component={ReportsContainer} />
    <Route path="/admin/opportunities/details" component={NewOpportunityDetailsContainer} />
    <Route path="/admin/opportunities/talent" component={TalentSelectionContainer} />
    <Route path="/admin/opportunities/new" component={NewOpportunityContainer} />
    <Route path="/admin/opportunities/view/:id" component={OpportunityStatContainer} />
    <Route path="/admin/opportunities/:id" component={EditOpportunityContainer} />
    <Route path="/admin/opportunities" component={OpportunitiyListAdminContainer} />
    <Route path="/admin/pipelines/:id/list" component={PipelineListContainer} />
    <Route path="/admin/pipelines/:id" component={PipelineContainer} />
    <Route path="/admin/pipelines" component={PipelineContainer} />
    <Route path="/admin/tasks" component={TaskContainer} />
    <Route path="/admin/match_preferences" component={MatchPreference} />
    <Route path="/admin/calibration_sessions/coming_soon" component={CalibrationSessionPlaceholder} />
    <Route path="/admin/calibration_sessions/new" component={NewCalibrationSession} />
    <Route path="/admin/calibration_sessions/:session_id" component={CalibrationSessionContainer} />
    <Route path="/admin/calibration_sessions" component={CalibrationSessionsContainer} />
    <Route path="/admin/activity_types" component={ActivityTypeContainer} />
    <Route path="/admin/educational_content" component={EducationalContentContainer} />
    <Route
      path="/admin/todo_lists/:todo_list_id/todo_items/:to_do_item_id/todo_item_questions"
      component={TodoItemQuestionsContainer}
    />
    <Route path="/admin/todo_lists/:todo_list_id/todo_items" component={TodoItemsContainer} />
    <Route path="/admin/todo_lists" component={TodoListsContainer} />
    <Route path="/admin/pending_authorized_users" component={PendingAuthorizedUsersContainer} />
    <Route path="/admin/dashboard" component={RecentActiveUsersContainer} />
    <Route path="/admin/deals/:id" component={DealContainer} />
    <Route path="/admin/deals" component={DealsContainer} />
    <Route path="/admin/suggestions" component={SuggestionsContainer} />
    <Route path="/admin/suggested_deals" component={SuggestedDealsContainer} />
    <Route path="/admin/payout_grids" component={PayoutGridContainer} />
    <Route path="/admin/tickers" component={TickersContainer} />
    <Route path="/admin/onboarding_banner_widgets" component={OnboardingBannerWidgetsContainer} />
    <Route path="/admin/Affiliations" component={AffiliationsContainer} />
  </Switch>
);
