import React from 'react';
import { Box, Typography } from '@material-ui/core/';
import CurrencyInput from 'react-currency-input-field';
import { useQuery } from 'react-apollo';
import { gql } from 'graphql-tag';
import { get } from 'lodash';
import numeral from 'numeral';

const PE_VALUATION_QUERY = gql`
  query peValuation($numberOfClients: Float!, $assetsUnderManagement: Float!, $feeBasedRevenue: Float!, $trailerRevenue: Float!, $directRevenue: Float!, $commissionRevenue: Float!) {
    peValuation(
      numberOfClients: $numberOfClients
      assetsUnderManagement: $assetsUnderManagement
      feeBasedRevenue: $feeBasedRevenue
      trailerRevenue: $trailerRevenue
      directRevenue: $directRevenue
      commissionRevenue: $commissionRevenue
    )
  }
`;
const PriceDetails = ({ formData, handleCurrencyChange }) => {
  const { loading, error, data } = useQuery(PE_VALUATION_QUERY, {
    variables: { ...formData },
  });

  const peValuation = data ? data.peValuation : null;
  const valuationLow = get(peValuation, '[0]', 0);
  const valuationHigh= get(peValuation, '[1]', 0);
  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        What is your asking price?
      </Typography>
      <CurrencyInput
        label="Asking Price"
        name="askingPrice"
        value={formData.askingPrice}
        onValueChange={(value, name) => handleCurrencyChange(value, name)}
        decimalScale={2}
        prefix="$"
        fullWidth
        margin="normal"
      />
      <hr/>
      <Typography variant="body1" gutterBottom>
        Based on the information you provided, we approximate the value of your segment to be between
        <strong> {numeral(valuationLow).format('$0,0.00')}</strong> and <strong>{numeral(valuationHigh).format('$0,0.00')}</strong>.
      </Typography>
    </Box>
  );
}

export default PriceDetails;
