import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Dialog, Card, CardContent, Typography, Button } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

const ActivityCard = ({isOpen,  onClose, activity, classes}) => {
  if(!activity) return null;
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" gutterBottom className={classes.title}>
            <FormattedMessage id="paddy.succession_readiness.succession_readiness" defaultMessage="Succession Readiness" />
          </Typography>
          <Typography variant="h6" gutterBottom>
            {activity.title}
          </Typography>
          <br/>
          <Typography 
            variant="body1" 
            gutterBottom
            dangerouslySetInnerHTML={{__html: activity.text}}>
          </Typography>
          <br/>
          <br/>
          <Typography variant="body1" gutterBottom className={classes.button}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => { 
                  if(activity.ctaType === 'url') {
                    window.location.href = activity.ctaTarget; 
                  }else if(activity.ctaType === 'javascript') {
                    eval(activity.ctaTarget);
                  }
                }}
                >
                {activity.ctaText}
              </Button>
          </Typography>
        </CardContent>
      </Card>
    </Dialog>
  );
}

ActivityCard.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  plan: PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    category: PropTypes.string.isRequired,
    cta: PropTypes.string.isRequired,
    ctaName: PropTypes.string.isRequired,
    dueDate: PropTypes.string.isRequired,
  }).isRequired,
};

const styles = {
  root: {
    maxWidth: 375,
  },
  button: {
    textAlign: 'center',
  },
  title: {
    textAlign: 'center',
  },
};

export default withStyles(styles)(ActivityCard);
