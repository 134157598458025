import React from 'react';
import PropTypes from 'prop-types';
import gql from "graphql-tag";
import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Provider } from 'react-intl-redux';
import { createIntlCache, IntlProvider } from 'react-intl';
import { ApolloProvider, Query, Mutation } from 'react-apollo';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { updateIntl } from 'react-intl-redux';
import client from '../lib/apolloClient';
import configureStore from '../store/findBobPublicStore';
import themes from '../lib/themes';
import defaultLocale from '../lib/i18n/default.json';
import translations from '../lib/i18n/translations.json';
import SuccessionReadinessBanner from '../components/SuccessionReadinessBanner/SuccessionReadinessBanner';

export const SuccessionReadinessQuery = gql`
  query SuccessionReadinessQuery {
    successionReadiness {
      id
      score
      completionPercentage
      yearsFromRetirement
      completed
      completionDate
      currentStepImage
      score
      maxScore
      roundedScore
      readinessActivities {
        id
        key 
        title
        text 
        score 
        completed
        category
        dueDate
        ctaTarget
        ctaText
        ctaType
      }
    }
  }
`;

const SuccessionReadinessWidgetContainer = (props, railsContext) => {
	const cache = createIntlCache();
  const theme = themes[railsContext.theme_name];
  const { classes } = props;

  const setIntl = () => {
	updateIntl(
	{
		locale   : defaultLocale,
		messages : translations[window.locale || 'en'],
	},
	cache
	);}

  let store = document.store;
	if (!store) {
		store = configureStore(props);
		document.store = store;
	}
  setIntl();

  return (
    <MuiThemeProvider theme={createMuiTheme(theme)}>
      <Provider store={document.store} {...props}>
        <IntlProvider messages={translations[window.locale || 'en']} locale={window.locale || 'en'}>
          <ApolloProvider client={client}>
            <SuccessionReadinessBanner {...props} />
          </ApolloProvider>
        </IntlProvider>
      </Provider>
    </MuiThemeProvider>
  );
}

SuccessionReadinessWidgetContainer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default SuccessionReadinessWidgetContainer;

