import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import { S3Image } from '../../lib/s3';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, List, ListItem, ListItemText } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import numeral from 'numeral';


const PartialSaleBoxInMessagesPage = ({ partialSale, currentUser, classes, convo }) => {
  let profilePicture = get(convo, 'participant1.profile_picture', '');
  if (partialSale && convo.participant2.id === currentUser.id) {
    profilePicture = get(convo, 'participant2.profile_picture', '');
  }

  return (
    <div className={classes.saleBox}>
      <h3 className={classes.thinLine}>{get(currentUser, 'company.partial_sale_name', 'Partial Sale')}</h3>
      <div className={classes.pictureBox}>
        {profilePicture && <img src={S3Image(profilePicture)} alt="profile" className={classes.profilePicture} />}

      </div>
      <center>
        <h5>{partialSale.friendly_name}</h5>
        <hr className={classes.thinLine} />
      </center>
      <div className={classes.saleCopy}>
        <div className={classes.segmentDetails}>{partialSale.segment_details}</div>
        <div>Asking Price: <span className={classes.askingPrice}> {numeral(partialSale.asking_price).format('$0,0.00')}</span></div>
        <div>Sale Status: <span className="badge" style={{textTransform: 'uppercase'}}>{partialSale.sale_status}</span></div>
        <br />
        <div>
          <ExpansionPanel defaultExpanded classes={{ root: classes.expansionPanel }}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
              Details
            </ExpansionPanelSummary>
            <ExpansionPanelDetails classes={{ root: classes.expansionPanelDetails }}>
              <List dense className={classes.list}>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Description" secondary={partialSale.description} />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Number Of Clients" secondary={partialSale.number_of_clients} />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="AUM" secondary={numeral(partialSale.assets_under_management).format('$0,0.00')} />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Fee Based Revenue" secondary={numeral(partialSale.fee_based_revenue).format('$0,0.00')} />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Trails Revenue" secondary={numeral(partialSale.trails_revenue).format('$0,0.00')} />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Direct Revenue" secondary={numeral(partialSale.direct_revenue).format('$0,0.00')} />
                </ListItem>
                <ListItem className={classes.listItem}>
                  <ListItemText primary="Commission Revenue" secondary={numeral(partialSale.commission_revenue).format('$0,0.00')} />
                </ListItem>
              </List>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    </div>
  );
}

export default PartialSaleBoxInMessagesPage;