import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import PartialSaleListing from '../PartialSaleListing/PartialSaleListing';
import PartialSaleWizard from '../PartialSaleWizard/PartialSaleWizard';

const PartialSaleListings = ({partialSales, user, company, partialSaleQuestions}) => {
  return (
    <>
      <PartialSaleWizard user={user} company={company} partialSaleQuestions={partialSaleQuestions} />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell >Status</TableCell>
              <TableCell>Segment</TableCell>
              <TableCell align="right">Views</TableCell>
              <TableCell align="right">Interests</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {partialSales.map((partialSale) => (
            <PartialSaleListing key={partialSale.name} partialSale={partialSale} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default PartialSaleListings;