import gql from 'graphql-tag';

export const CreateChatQuery = gql`
  mutation createChat($userName: String!) {
    createChat(userName: $userName) {
      id
    }
  }
`;

export const MatchProfileQuery = gql`
  query MatchProfileQuery($dealInterestId: ID!) {
    currentUserInfoAuth
    companyHasCounty(featureName: "show_county")
    countryForCompany
    agentWordForCompany
    getDealInterest(id: $dealInterestId) {
      id
      workflow_state
      invited
      saved
      shortlisted
      proposal
      proposal_letter
      receiving_party_nda_sent_timestamp
      conversation_id
      reason_for_suggestion
      proposal_meeting_availability
      deal_scheduled_meetings {
        id
        start_time
        duration
        format
      }
      deal {
        id
        headline
        deal_name
      }
      documents {
        id
        name
        download_url
        category
        uploaded_by {
          name
        }
        created_at
        mime_type
      }
      inventory_user {
        id
        affiliation
        branch
        division
        user {
          id
          user_name
          allowed_name
          fb_user_name
          allowed_phone
          annual_revenue
          assets_under_management
          avg_client_age
          bio
          city
          county
          show_city
          number_of_clients
          profile_picture
          province
          recurring_revenue
          created_at
          headline
          my_rating
          nick_name
          obfuscated
          is_pure_buyer
          is_admin
          designations
          tag_list
          transition_goals
          in_auction
          years_practicing
          number_of_employees
          percent_revenue_recurring
          percent_non_recurring_revenue
          percent_fee_based
          percent_commission
          mix_insurance
          mix_mutual_funds
          mix_annuities
          mix_other
          note_count
          notes {
            id
            comment
            created_at
          }
          business_continuity_state
          languages
          defined_investment_style
          defined_service_model
          part_of_team
          team_size
          niche_market
          expressed_interest
          product_mix {
            life_ifp
            home_ifp
            auto_ifp
            commercial_ifp
            health_ifp
            group_ifp
            mutual_funds_aum
            securities_aum
            annuities_aua
            segregated_funds_aua
            total_assets
            mcpi_ifp
            private_products_ifp
            crop_hail_ifp
            crop_hail_with_wind_ifp
            farm_insurance_ifp
            price_products_ifp
            replant_supplement_ifp
            gic_fixed_income
            equity
          }
          affiliations {
            id
            name
          }
        }
      }
    }
    getCurrentUser {
      user_name
      name
      locale
      roles
      has_selling_transition_goals
      planning_preference
      limited_access
      company {
        id
        name
        branded_name
        seller_only_label
        deal_room_branded_name
      }
    }
    getSpotlights(spotlights: ["nick_name", "notebook"])
    availableProducts
    availableStrengths
  }
`;

export default MatchProfileQuery;
