import React from 'react';
import PropTypes from 'prop-types';
import { Drawer } from '@material-ui/core';
import ChatWidget from '../ChatWidget/ChatWidget';
import { set } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import SuccessionReadinessStatus from './SuccessionReadinessStatus';

const CreateSuccessionReadiness = gql`
  mutation CreateSuccessionReadiness($yearsFromRetirement: Int!) {
    createSuccessionReadiness(yearsFromRetirement: $yearsFromRetirement) {
      id
      conversationId
      score
      completionPercentage
      yearsFromRetirement
      completed
      completionDate
      currentStepImage
      readinessActivities {
        id
        key 
        title
        text 
        score 
        completed
        category
        dueDate
        ctaTarget
        ctaText
        ctaType
      }
    }
  }
`;

//const PaddyChatDrawer = (props) => {
class PaddyChatDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.onClose = this.onClose.bind(this);
    this.state = {
      open: false,
      roomId: props.roomId,
      newMessage: 0,
    }

    this.onNewMessage = this.onNewMessage.bind(this);
  }

  onClose = () => {
    this.setState({open: false});
  }

  onNewMessage = () => {
    const { newMessage } = this.state;
    this.setState({newMessage: newMessage + 1});
  }
  
  componentWillReceiveProps(nextProps) {
    if (nextProps.open !== this.props.open && nextProps.open == true) {
      this.setState({open: nextProps.open});
    }
  }

  render() {
    const { classes, yearsFromRetirement, createCallback, completed } = this.props;
    const { open, roomId,newMessage } = this.state;

    if (!open && roomId) {
      if(!completed) {
        return <Button 
          variant="contained"
          color="secondary"
          onClick={() => this.setState({open: true})}>
            Resume Assessment
          </Button>;
 
      }
   }else if (!open) {
      return <Mutation 
                mutation={CreateSuccessionReadiness}
                variables={{ yearsFromRetirement }}
                onCompleted={data => {
                  const { conversationId } = data.createSuccessionReadiness;
                  this.setState({open: true, roomId: conversationId}, createCallback(data.createSuccessionReadiness));
                }}>
              {(createSuccessionReadiness, { data }) =>
                <Button variant="contained" color="secondary" style={{ textTransform: 'none' }} onClick={() => {
                    createSuccessionReadiness();
                  }}>Start Assessment 
                  </Button>
              }
            </Mutation>;
    }
    return (
      <>
        <Drawer 
          anchor="right"
          variant="persistent"
          open={open}
          onClose={this.onClose}
          classes={{
            paper : classes.drawerPaper,
          }}
          >
          <div className={classes.header}>
            <IconButton key="close" aria-label="Close" color="inherit" className={classes.close} onClick={this.onClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={classes.body}>
            <div className={classes.chatWidget}>
              { roomId && <ChatWidget
                            room_id={roomId}
                            {...this.props}
                            title="Succession Readiness"
                            standalone
                            onNewMessage={this.onNewMessage} /> }
            </div>
            <div className={classes.status}>
              <h3>Assessment Status</h3>
              <SuccessionReadinessStatus refreshCount={newMessage}/>
            </div>
          </div>
          </Drawer>
      </>
    );
  }
}

const style = theme => ({
  drawerPaper : {
    width        : '70%',
    top          : 68,
    borderRadius : 4,
  },
  close       : {
    float : 'right',
    color: 'white',
  },
  header      : {
    height       : 64,
    borderBottom : '1px solid #eee',
    backgroundColor: theme.palette.primary.main,
    padding: '20px 8px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px 8px',
    flexDirection: 'row',
  },
  chatWidget  : {
    flex: 2,
    width: '100%',
  },
  status      : {
    flex: 1,
    width: '100%',
    margin: 20,
    padding: '0 20px',
  },
});

PaddyChatDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  yearsFromRetirement: PropTypes.number.isRequired,
  createCallback: PropTypes.func.isRequired,
  roomId: PropTypes.number,
};

export default withStyles(style)(PaddyChatDrawer);