import React from 'react';
import Chip from '@material-ui/core/Chip';
import moment from 'moment';
import numeral from 'numeral';
import { get, filter, find, startCase, upperFirst, reject, lowerCase } from 'lodash';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import EditIcon from '@material-ui/icons/BlurOn';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Avatar from '@material-ui/core/Avatar';
import initials from 'initials';
import { regionForCountry, bgColorForName } from '../../lib/findBobUtils';
import { S3Image } from '../../lib/s3';
import InviteButton from '../InviteButton/InviteButton';
import { ifpOptions } from '../SignUpWizard/ProductMix';
import { interestsChip } from '../InventoryUserDrawer/OverviewUserData';
import { da } from 'date-fns/locale';

/*
{
    id             : 'birth_date',
    dataType       : 'number',
    disablePadding : false,
    label          : 'Age',
    filter         : true,
    filterType     : 'range',
    customStyle : {}, // optional
    renderMethod   : n => {},
    },
*/

const render_ifp = (n, id, intl) => {
  return get(find(ifpOptions(intl), { value: get(n, id) }), 'label', '-');
};

const render_dollar_values = (n, att) => {
  return get(n, att, null) !== null ? numeral(get(n, att, 0)).format('$0,0') : '-';
};

const render_distance_values = (n, att) => {
  return get(n, att, null) !== null ? numeral(get(n, att, 0)).format('0,0.00') : '-';
};

const render_bool = (n, att) => {
  const val = get(n, att, null);
  if (val !== null) {
    return val === true ? 'Yes' : 'No';
  }
  return '-';
};

const ifp_template = {
  id             : '',
  dataType       : 'disableSort',
  disablePadding : false,
  label          : '',
  filter         : true,
  filterType     : 'range',
  renderMethod   : n => render_ifp(n, ''),
};

export const fb_columns = ({
  inventory_users,
  updateInventoryUser,
  updateSelectedInventoryUser,
  classes,
  data,
  tags,
  intl,
  company,
}) => {
  const filterTags = [ { name: 'None', __typename: 'Tags' }, ...tags ];
  const companyColumns = [
    {
      id             : 'name',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Name',
      filter         : false,
      customStyle    : {
        position   : 'sticky',
        left       : 50,
        zIndex     : 10,
        background : 'white',
      },
      customWidth    : 150,
      renderMethod   : inventoryUser => (
        <span className={classes.nameField}>
          <span className={classes.iconContainer}>
            <Avatar
              size="24"
              name={inventoryUser.name}
              src={inventoryUser.has_user ? S3Image(inventoryUser.profile_picture) : null}
              className={classes.avatar}
              style={{ backgroundColor: bgColorForName(inventoryUser.name) }}>
              <span className={classes.avatarText}>{initials(inventoryUser.name)}</span>
            </Avatar>
            {inventoryUser.hidden && <VisibilityOff color="primary" />}
            {inventoryUser.is_customer && <VerifiedUserIcon color="primary" />}
            {inventoryUser.is_obfuscated && <EditIcon color="primary" />}
            {inventoryUser.limited_access && <i class="material-icons">attach_money</i>}
            {inventoryUser.omit && <i class="material-icons">location_off</i>}
          </span>
          <span style={{ verticalAlign: 'middle' }}>{inventoryUser.name}</span>
        </span>
      ),
    },
    {
      id             : 'email',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Email',
      filter         : false,
      renderMethod   : n => n.email || '-',
    },
    {
      id             : 'users.user_name',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'FB User Name',
      filter         : false,
      renderMethod   : n => get(n, 'user.user_name', '-'),
    },
    {
      id             : 'marketplace_status',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Marketplace Status',
      filter         : true,
      ransack_filter : 'marketplace_status',
      filterOptions  :
        company.business_type === 'wealth_rj'
          ? [ { name: 'Invite', value: '0' }, { name: 'Pending', value: '1' }, { name: 'Joined', value: '2' } ]
          : [
              { name: 'Invite', value: '0' },
              { name: 'Pending', value: '1' },
              { name: 'Joined', value: '2' },
              { name: 'Activated', value: '3' },
            ],
      renderMethod   : inventoryUser => (
        <span className={classes.invite}>
          <InviteButton
            type={inventoryUser.marketplace_status}
            inviteeId={inventoryUser.id}
            inviteeName={inventoryUser.name}
            inventory_users={inventory_users}
            updateInventoryUser={updateInventoryUser}
            updateAction={updateSelectedInventoryUser}
            company={inventoryUser.company}
          />
        </span>
      ),
    },
    {
      id             : 'user_created_at',
      dataType       : 'date',
      disablePadding : false,
      label          : 'Joined Date',
      filterType     : 'range',
      filter         : true,
      ransack_filter : 'user_created_at',
      renderMethod   : n => moment(get(n, 'user.created_at', '-')).format('MM/DD/YYYY'),
    },
    {
      id             : 'transition_goals',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Transition Goals',
      filter         : true,
      ransack_filter : 'transition_goals_name',
      filterOptions  : [
        { name: 'None', value: 'None' },
        { name: 'Buying', value: 'Buying' },
        { name: 'Merging', value: 'Merging' },
        { name: 'Partnering', value: 'Partnering' },
        { name: 'Business Continuity', value: 'Business Continuity' },
        { name: 'Find a Successor', value: 'Find a Successor' },
        { name: 'Become a Successor', value: 'Become a Successor' },
        { name: 'Becoming a Junior Partner', value: 'Becoming a Junior Partner' },
        { name: 'Selling my Practice', value: 'Selling my practice' },
        {
          name  : 'Selling part of my practice',
          value : 'Selling part of my practice',
        },
        { name: 'Mentoring ', value: 'Mentoring' },
        { name: 'Being Mentored', value: 'Being Mentored' },
      ],
      renderMethod   : n => (n.transition_goals || []).map(t => interestsChip(t, classes)),
    },
    {
      id             : 'user_business_continuity_state_name',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Business Continuity State',
      filter         : true,
      ransack_filter : 'user_business_continuity_state_name',
      filterOptions  : [
        { name: 'None', value: 'None' },
        {
          name  : 'Yes, with an identified partner incl. financing',
          value : 'Yes, with an identified partner incl. financing',
        },
        {
          name  : 'Yes, with an identified partner',
          value : 'Yes, with an identified partner',
        },
      ],
      renderMethod   : n => get(n, 'user.business_continuity_state', '') || '-',
    },
    {
      id             : 'province',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Province/State',
      filter         : true,
      ransack_filter : 'province',
      filterOptions  : regionForCountry(
        company.country,
        location.hostname === 'app.findbob.io' || location.hostname === 'staging.findbob.io'
      ).map(c => ({ name: c, value: c })),
    },
    {
      id             : 'city',
      dataType       : 'string',
      disablePadding : false,
      label          : 'City',
      filter         : false,
      ransack_filter : 'city',
      renderMethod   : n => n.city || '-',
    },
    {
      id             : 'referral_code',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Referral Code',
      filter         : true,
      renderMethod   : n => get(n, 'user.referral_code', '') || '-',
    },
    {
      id             : 'birth_date',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Age',
      filter         : true,
      ransack_filter : 'birth_date',
      filterType     : 'date',
      reverseOrderBy : true,
      renderMethod   : n => moment().diff(n.birth_date, 'years') || '-',
    },
    {
      id             : 'start_date',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Years With Company',
      filterType     : 'date',
      filter         : true,
      ransack_filter : 'start_date',
      renderMethod   : n => `${moment().diff(n.start_date, 'years')} years`,
    },
    {
      id             : 'user_profile_percentage_complete',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : '% of Profile Complete',
      filterType     : 'range',
      filter         : true,
      ransack_filter : 'user_profile_percentage_complete',
      renderMethod   : n => `${get(n, 'user.profile_percentage_complete', '')} %`,
    },
    {
      id             : 'minimum_profile_completed',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Minimum Profile Completed',
      filter         : true,
      ransack_filter : 'minimum_profile_completed',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'minimum_profile_completed'),
    },
    {
      id             : 'user_annual_revenue',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Revenue',
      filter         : true,
      filterType     : 'range',
      ransack_filter : 'user_annual_revenue',
      renderMethod   : n => render_dollar_values(n, 'user.annual_revenue'),
    },
    {
      id             : 'user_valuation_last_accessed_date',
      dataType       : 'date',
      disablePadding : false,
      label          : 'Valuation Last Accessed Date',
      filterType     : 'range',
      filter         : true,
      ransack_filter : 'user_valuation_last_accessed_date',
      renderMethod   : n =>
        moment(get(n, 'user.valuation_last_accessed_date')).isValid()
          ? moment(get(n, 'user.valuation_last_accessed_date')).format('MM/DD/YYYY')
          : '-',
    },
    {
      id             : 'user_product_mix_home_ifp',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Home IFP (Millions)',
      filter         : true,
      filterType     : 'range',
      ransack_filter : 'user_product_mix_home_ifp',
      renderMethod   : n =>
        get(
          find(ifpOptions(intl), {
            value : get(n, 'user.product_mix.home_ifp'),
          }),
          'label',
          '-'
        ),
    },
    {
      id             : 'user_product_mix_life_ifp',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Life IFP (Millions)',
      filter         : true,
      ransack_filter : 'user_product_mixes_life_ifp',
      filterType     : 'range',
      renderMethod   : n =>
        get(
          find(ifpOptions(intl), {
            value : get(n, 'user.product_mix.life_ifp'),
          }),
          'label',
          '-'
        ),
    },
    {
      id             : 'user_product_mix_auto_ifp',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Auto IFP',
      filter         : true,
      filterType     : 'range',
      ransack_filter : 'user_product_mix_auto_ifp',
      renderMethod   : n =>
        get(
          find(ifpOptions(intl), {
            value : get(n, 'user.product_mix.auto_ifp'),
          }),
          'label',
          '-'
        ),
    },
    {
      id             : 'user_product_mix_commercial_ifp',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Commercial IFP',
      filter         : true,
      filterType     : 'range',
      ransack_filter : 'user_product_mix_commercial_ifp',
      renderMethod   : n =>
        get(
          find(ifpOptions(intl), {
            value : get(n, 'user.product_mix.commercial_ifp'),
          }),
          'label',
          '-'
        ),
    },
    {
      id             : 'user_hidden',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Hidden',
      filter         : true,
      ransack_filter : 'user_hidden',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'user.hidden'),
    },
    {
      id             : 'iu_tags_name',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Tags',
      filter         : true,
      ransack_filter : 'iu_tags_name',
      renderMethod   : n =>
        (n.iu_tags || [])
          .map(t => (t.name.includes('csv') ? <Chip label={t.name} /> : <Chip label={upperFirst(t.name)} />)),
      filterOptions  : filterTags.map(g => ({ name: g.name, value: g.name })),
    },
    {
      id             : 'managed_by',
      dataType       : 'user',
      filterType     : 'manager',
      disablePadding : false,
      label          : 'Managed By',
      filter         : true,
      ransack_filter : 'managed_by_users_id',
      renderMethod   : n => get(n, 'manager.name', ''),
    },
    {
      id             : 'is_invisible',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Invisible?',
      filter         : true,
      ransack_filter : 'is_invisible',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_invisible'),
    },
    {
      id            : 'is_student',
      dataType      : 'string',
      disablePadding : false,
      label         : 'Student?',
      filter        : true,
      ransack_filter : 'is_student',
      filterOptions : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod  : n => render_bool(n, 'is_student'),
    },
    {
      id             : 'key_asset',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Key Asset?',
      filter         : true,
      ransack_filter : 'key_asset',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'key_asset'),
    },
    {
      id             : 'user.approval_state',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Approval Status',
      filter         : true,
      ransack_filter : 'user_approval_state',
      filterOptions  : [
        { name: 'Pending', value: 'pending' },
        { name: 'Approved', value: 'approved' },
        { name: 'Denied', value: 'denied' },
      ],
      renderMethod   : n => get(n, 'approval_state', ''),
    },
    {
      id             : 'risk_of_loss_num',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Risk Of Loss',
      filter         : true,
      ransack_filter : 'risk_of_loss_num',
      filterOptions  : [
        { name: 'None', value: '0' },
        { name: 'Low', value: '1' },
        { name: 'Medium', value: '2' },
        { name: 'High', value: '3' },
      ],
      renderMethod   : n => (n.risk_of_loss_num ? startCase(n.risk_of_loss_num.replace('risk', '')) : 'None'),
    },
    {
      id             : 'impact_of_loss_num',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Impact Of Loss',
      filter         : true,
      ransack_filter : 'impact_of_loss_num',
      filterOptions  : [
        { name: 'None', value: '0' },
        { name: 'Low', value: '1' },
        { name: 'Medium', value: '2' },
        { name: 'High', value: '3' },
      ],
      renderMethod   : n => (n.impact_of_loss_num ? startCase(n.impact_of_loss_num.replace('impact', '')) : 'None'),
    },
    {
      id             : 'bench_count',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Bench Nominations',
      renderMethod   : n => n.bench_count || '-',
    },
    {
      ...ifp_template,
      id           : 'avg_client_age',
      label        : 'Avg. Client Age',
      renderMethod : n => n.avg_client_age || '-',
    },
    {
      id             : 'user_recurring_revenue',
      dataType       : 'disableSort',
      disablePadding : false,
      label          : 'Recurring Revenue',
      filter         : true,
      filterType     : 'range',
      ransack_filter : 'user_recurring_revenue',
      renderMethod   : n => render_dollar_values(n, 'user.recurring_revenue'),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_health_ifp',
      label          : 'Health ifp',
      ransack_filter : 'user_product_mix_health_ifp',
      renderMethod   : n => render_ifp(n, 'user.product_mix.health_ifp', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_group_ifp',
      label          : 'Group ifp',
      ransack_filter : 'user_product_mix_group_ifp',
      renderMethod   : n => render_ifp(n, 'user.product_mix.group_ifp', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_mutual_funds_aum',
      label          : 'Mutual Funds (aum)',
      ransack_filter : 'user_product_mix_mutual_funds_aum',
      renderMethod   : n => render_ifp(n, 'user.product_mix.mutual_funds_aum', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_securities_aum',
      label          : 'Securities (aum)',
      ransack_filter : 'user_product_mix_securities_aum',
      renderMethod   : n => render_ifp(n, 'user.product_mix.securities_aum', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_annuities_aua',
      label          : 'Annuities (aua)',
      ransack_filter : 'user_product_mix_annuities_aua',
      renderMethod   : n => render_ifp(n, 'user.product_mix.annuities_aua', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_segregated_funds_aua',
      label          : 'Segregated Funds (aua)',
      ransack_filter : 'user_product_mix_segregated_funds_aua',
      renderMethod   : n => render_ifp(n, 'user.product_mix.segregated_funds_aua', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_mcpi_ifp',
      label          : 'MCPI (ifp)',
      ransack_filter : 'user_product_mix_mcpi_ifp',
      renderMethod   : n => render_ifp(n, 'user.product_mix.mcpi_ifp', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_private_products_ifp',
      label          : 'Private Products (ifp)',
      ransack_filter : 'user_product_mix_private_products_ifp',
      renderMethod   : n => render_ifp(n, 'user.product_mix.private_products_ifp', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_crop_hail_ifp',
      label          : 'Crop Hail (ifp)',
      ransack_filter : 'user_product_mix_crop_hail_ifp',
      renderMethod   : n => render_ifp(n, 'user.product_mix.crop_hail_ifp', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_crop_hail_with_wind_ifp',
      label          : 'Crop Hail with Wind (ifp)',
      ransack_filter : 'user_product_mix_crop_hail_with_wind_ifp',
      renderMethod   : n => render_ifp(n, 'user.product_mix.crop_hail_with_wind_ifp', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_price_products_ifp',
      label          : 'Price Products (ifp)',
      ransack_filter : 'user_product_mix_price_products_ifp',
      renderMethod   : n => render_ifp(n, 'user.product_mix.price_products_ifp', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_replant_supplement_ifp',
      label          : 'Replant Supplement (ifp)',
      ransack_filter : 'user_product_mix_replant_supplement_ifp',
      renderMethod   : n => render_ifp(n, 'user.product_mix.replant_supplement_ifp', intl),
    },
    {
      ...ifp_template,
      id             : 'user_product_mix_farm_insurance_ifp',
      label          : 'Farm Insurance (ifp)',
      ransack_filter : 'user_product_mix_farm_insurance_ifp',
      renderMethod   : n => render_ifp(n, 'user.product_mix.farm_insurance_ifp', intl),
    },
    {
      ...ifp_template,
      id             : 'user_clients',
      label          : 'Clients',
      ransack_filter : 'user_clients',
      renderMethod   : n => get(n, 'user.number_of_clients', '-'),
    },
    {
      id             : 'region',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Region',
      filter         : false,
      ransack_filter : 'region',
      renderMethod   : n => n.region || '-',
    },
    {
      id             : 'affiliation',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Affiliation',
      filter         : false,
      ransack_filter : 'affiliation',
      renderMethod   : n => n.affiliation || '-',
    },
    {
      id             : 'division',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Division',
      filter         : false,
      ransack_filter : 'division',
      renderMethod   : n => n.division || '-',
    },
    {
      id             : 'branch',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch',
      filter         : false,
      ransack_filter : 'branch',
      renderMethod   : n => n.branch || '-',
    },
    {
      id             : 'is_branch_exception',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch Exclusion',
      filter         : true,
      ransack_filter : 'is_branch_exception',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_branch_exception'),
    },
    {
      id             : 'website',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Website',
      filter         : false,
      ransack_filter : 'website',
      renderMethod   : n => n.website || '-',
    },
    {
      id             : 'catestrophic_plan_on_file',
      dataType       : 'string',
      disablePadding : false,
      label          : `${get(company, 'catastrophic_planning_word', 'Catastrophic')} Plan On File`,
      filter         : false,
      ransack_filter : 'catestrophic_plan_on_file',
      renderMethod   : n => n.catestrophic_plan_on_file || '-',
    },
    {
      id             : 'alex_brown_flag',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Alex Brown Flag',
      filter         : true,
      ransack_filter : 'alex_brown_flag',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'alex_brown_flag'),
    },
    {
      id             : 'recognition_level',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Recognition Level',
      filter         : true,
      filterOptions  : [
        { name: 'Achievers Club', value: 'Achievers Club' },
        { name: "Chairman's Council", value: "Chairman's Council" },
        { name: "Chairman's Council-1", value: "Chairman's Council-1" },
        { name: "Chairman's Council-2", value: "Chairman's Council-2" },
        { name: "Chairman's Council-3", value: "Chairman's Council-3" },
        { name: "Chairman's Council-4", value: "Chairman's Council-4" },
        { name: 'Executive Council', value: 'Executive Council' },
        { name: "FID Director's Council", value: "FID Director's Council" },
        { name: "ICD Director's Council", value: "ICD Director's Council" },
        { name: 'Leaders Club-1', value: 'Leaders Club-1' },
        { name: 'Leaders Club-2', value: 'Leaders Club-2' },
        { name: 'Leaders Council', value: 'Leaders Council' },
        { name: "President's Club-1", value: "President's Club-1" },
        { name: "President's Club-2", value: "President's Club-2" },
      ],
      ransack_filter : 'recognition_level',
    },
    {
      id             : 'user_industry_length_of_service',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Industry Length of Service',
      filterType     : 'range',
      filter         : true,
      ransack_filter : 'user_industry_length_of_service',
      renderMethod   : n => get(n, 'user.years_practicing') ? `${get(n, 'user.years_practicing')} years` : '-',
    },
    {
      id             : 'team_size',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Team Size',
      filter         : true,
      ransack_filter : 'team_size',
      filterType     : 'range',
      renderMethod   : n => get(n, 'team_size'),
    },
    {
      id             : 'amp_trainee_status',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Amp Trainee Status',
      filter         : true,
      ransack_filter : 'amp_trainee_staus',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'amp_trainee_status'),
    },
    {
      id             : 'heightened_supervision',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Heightened Supervision',
      filter         : true,
      ransack_filter : 'heightened_supervision',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'heightened_supervision'),
    },
    {
      id             : 'cssid',
      dataType       : 'number',
      disablePadding : false,
      label          : 'CSSID',
      ransack_filter : 'cssid',
      renderMethod   : n => get(n, 'cssid'),
    },
    {
      id             : 'office_city',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch City',
      filter         : false,
      ransack_filter : 'office_city',
      renderMethod   : n => n.office_city || '-',
    },
    {
      id             : 'office_state',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch State',
      filter         : true,
      filterOptions  : regionForCountry(
        company.country,
        location.hostname === 'app.findbob.io' || location.hostname === 'staging.findbob.io'
      ).map(c => ({ name: c, value: c })),
      ransack_filter : 'office_state',
      renderMethod   : n => n.office_state || '-',
    },
    {
      id             : 'office_postal_code',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Branch Zip',
      filter         : false,
      ransack_filter : 'office_postal_code',
      renderMethod   : n => n.office_postal_code || '-',
    },
    {
      id             : 'insurance_business',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Insurance Business',
      filter         : true,
      ransack_filter : 'insurance_business',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'insurance_business'),
    },
    {
      id             : 'discretion',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Discretion',
      filter         : true,
      ransack_filter : 'discretion',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'discretion'),
    },
    {
      ...ifp_template,
      id             : 'user_trailer_revenue',
      label          : company.business_type === 'wealth_rj' ? 'Trail Revenue' : 'Trailer Revenue',
      ransack_filter : 'user_trailer_revenue',
      renderMethod   : n => render_dollar_values(n, 'user.trailer_revenue'),
    },
    {
      ...ifp_template,
      id             : 'user_direct_revenue',
      label          : 'Direct Revenue',
      ransack_filter : 'user_direct_revenue',
      renderMethod   : n => render_dollar_values(n, 'user.direct_revenue'),
    },
    {
      ...ifp_template,
      id             : 'user_transactional_revenue',
      label          : 'Commissions Revenue',
      ransack_filter : 'user_transactional_revenue',
      renderMethod   : n => render_dollar_values(n, 'user.transactional_revenue'),
    },
    {
      ...ifp_template,
      id             : 'user_fee_based_revenue',
      label          : 'Fee Based Revenue',
      ransack_filter : 'user_fee_based_revenue',
      renderMethod   : n => render_dollar_values(n, 'user.fee_based_revenue'),
    },
    {
      ...ifp_template,
      id             : 'user_total_assets',
      label          : 'Assets Under Management',
      ransack_filter : 'user_total_assets',
      renderMethod   : n => render_dollar_values(n, 'user.assets_under_management'),
    },
    {
      ...ifp_template,
      id             : 'pct_client_0to18',
      label          : 'Clients Aged 0 to 18',
      ransack_filter : 'pct_client_0to18',
      renderMethod   : n => `${get(n, 'pct_client_0to18', 0)}%`,
    },
    {
      ...ifp_template,
      id             : 'pct_client_18to34',
      label          : 'Clients Aged 18 to 34',
      ransack_filter : 'pct_client_18to34',
      renderMethod   : n => `${get(n, 'pct_client_18to34', 0)}%`,
    },
    {
      ...ifp_template,
      id             : 'pct_client_35to50',
      label          : 'Clients Aged 35 to 50',
      ransack_filter : 'pct_client_35to50',
      renderMethod   : n => `${get(n, 'pct_client_35to50', 0)}%`,
    },
    {
      ...ifp_template,
      id             : 'pct_client_51to70',
      label          : 'Clients Aged 51 to 70',
      ransack_filter : 'pct_client_51to70',
      renderMethod   : n => `${get(n, 'pct_client_51to70', 0)}%`,
    },
    {
      ...ifp_template,
      id             : 'pct_client_71plus',
      label          : 'Clients Aged 71 Plus',
      ransack_filter : 'pct_client_71plus',
      renderMethod   : n => `${get(n, 'pct_client_71plus', 0)}%`,
    },
    {
      id             : 'series_7',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Series 7',
      filter         : true,
      ransack_filter : 'series_7',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'series_7'),
    },
    {
      id             : 'series_63',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Series 63',
      filter         : true,
      ransack_filter : 'series_63',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'series_63'),
    },
    {
      id             : 'series_65',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Series 65',
      filter         : true,
      ransack_filter : 'series_65',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'series_65'),
    },
    {
      id             : 'series_66',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Series 66',
      filter         : true,
      ransack_filter : 'series_66',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'series_66'),
    },
    {
      id             : 'retirement_choice_calculator_code',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Retirement Choice Calculator Code',
      filter         : false,
      ransack_filter : 'retirement_choice_calculator_code',
    },
    {
      id             : 'state_registrations',
      dataType       : 'string',
      disablePadding : false,
      label          : 'State Registrations',
      filter         : true,
      ransack_filter : 'state_registrations',
      filterOptions  : regionForCountry(
        company.country,
        location.hostname === 'app.findbob.io' || location.hostname === 'staging.findbob.io'
      ).map(c => ({ name: `${c}`, value: lowerCase(c) })),
      renderMethod   : n => get(n, 'state_registrations', []).map(s => `${s} `),
    },
    {
      id             : 'can_match',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Can Match',
      filter         : true,
      ransack_filter : 'can_match',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'can_match'),
    },    
    {
      id             : 'can_create_meeting_room',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Can Create Meeting Room',
      filter         : true,
      ransack_filter : 'can_create_meeting_room',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'can_create_meeting_room'),
    },
    {
      id             : 'is_rjas',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Is RJAs',
      filter         : true,
      ransack_filter : 'is_rjas',
      filterOptions  : [ { name: 'True', value: 'true' }, { name: 'False', value: 'false' } ],
      renderMethod   : n => render_bool(n, 'is_rjas'),
    }, 
    {
      id             : 'user_branch_net_payout',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Branch Net Payout',
      filterType     : 'range',
      filter         : true,
      ransack_filter : 'user_branch_net_payout',
      renderMethod: n => `${(get(n, 'user.branch_net_payout', 0) * 100).toFixed(0)}%`,
    },
    {
      id             : 'market_value',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Market Value',
      filterType     : 'number',
      filter         : true,
      ransack_filter : 'market_value',
      renderMethod   : n => render_dollar_values(n, 'market_value'),
    },
    {
      id             : 'distance_from_search',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Distance From Search',
      filter         : true,
      ransack_filter : 'distance_from_search',
      renderMethod   : n => render_distance_values(n, 'distance_from_search'),
    },
    {
      id             : 'market_value_percentile',
      disablePadding : false,
      label          : 'Market Value Percentile',
      filterType     : 'range',
      filter         : true,
      ransack_filter : 'market_value_percentile',
      renderMethod   : n => `${get(n, 'market_value_percentile', '')} %`,
    },
    {
      id             : 'updated_at',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Updated At',
      filterType     : 'date',
      filter         : false,
      ransack_filter : 'updated_at',
      renderMethod   : n =>
        moment(n.updated_at).isValid() ? moment(n.updated_at).format('lll') : '-',
    },
    {
      id             : 'planning_preference',
      dataType       : 'string',
      disablePadding : false,
      label          : 'Planning Preference',
      filter         : true,
      ransack_filter : 'planning_preference',
      filterOptions  : [
            { name: 'None', value: '' },
            { name: 'Find Partner', value: 'find_partner' },
            { name: 'Become Partner', value: 'become_partner' },
            { name: 'No Plan Preference', value: 'no_plan_preference' },
          ],
      renderMethod   : n => get(n, 'planning_preference'),
    },
    {
      id             : 'created_at',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Created At',
      filterType     : 'date',
      filter         : false,
      ransack_filter : 'created_at',
      renderMethod   : n =>
        moment(n.created_at).isValid() ? moment(n.created_at).format('lll') : '-',
    },
    {
      id             : 'last_catastrophic_plan_downloaded_date',
      dataType       : 'number',
      disablePadding : false,
      label          : 'Last Catastrophic Plan Downloaded Date',
      filterType     : 'date',
      filter         : false,
      ransack_filter : 'last_catastrophic_plan_downloaded_date',
      renderMethod   : n =>
        moment(get(n, 'user.last_catastrophic_plan_downloaded_date')).isValid()
        ? moment(get(n, 'user.last_catastrophic_plan_downloaded_date')).format('lll')
        : '-',
    },
  ];

  let hiddenCols = get(company, 'hidden_inventory_user_columns', [ '' ]);
  if (get(company, 'add_age_column_to_tables')) {
    hiddenCols = hiddenCols.filter(e => e !== 'birth_date');
  }
  return reject(companyColumns, c => hiddenCols.includes(c.id));
};

export const filteredUserColumns = props => {
  const columns = fb_columns(props);
  const { inventory_users } = props;
  const selected = inventory_users.selectedColumns || [ '' ];
  return filter(columns, c => selected.includes(c.id));
};

export default fb_columns;
