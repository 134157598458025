import React, { useState } from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import * as actions from '../../actions/userActionCreators';

const UpdateActivityTypeMutation = gql`
  mutation pullRevenueInformationForUser {
    pullRevenueInformationForUser {
      id
      trailer_revenue
      direct_revenue
      transactional_revenue
      fee_based_revenue
      years_practicing
      number_of_clients
      annual_revenue
      recurring_revenue
      assets_under_management
      avg_client_age
    }
  }
`;

export const RjPullRevenueInformationButton = ({ user, userUpdate }) => {
  const [ open, setOpen ] = useState(false);
  return (
    <Mutation mutation={UpdateActivityTypeMutation}>
      {mutate => (
        <center>
          <Button
            variant="contained"
            color="primary"
            style={{ backgroundColor: '#5489b6', color: '#fff' }}
            onClick={() => setOpen(!open)}>
            Pull Latest Raymond James Data
          </Button>
          <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">Confirmation</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                By clicking okay Practice Exchange will pull the most recent data from Raymond James and overwrite any
                revenue information that has been entered in your Practice Exchange profile. Do you wish to proceed?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpen(!open)} color="primary">
                Disagree
              </Button>
              <Button
                onClick={() => {
                  mutate().then(response => {
                    setOpen(!open);
                    const currentUrl = window.location.href;
                    window.history.pushState({}, document.title, `${currentUrl.substring(0, currentUrl.indexOf('?'))}`);
                    location.reload();
                  });
                }}
                color="primary"
                autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
        </center>
      )}
    </Mutation>
  );
};

const mapStateToProps = state => ({
  user : state.user,
});

export default connect(mapStateToProps, actions)(RjPullRevenueInformationButton);
