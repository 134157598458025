import React, { useState } from 'react';
import { Container, Button, Stepper, Step, StepLabel, Box, Dialog, DialogContent, DialogActions, CardMedia } from '@material-ui/core';
import FinancialDetails from './FinancialDetails';
import Description from './Description';
import Details from './Details';
import PriceDetails from './PriceDetails';
import Review from './Review';
import Publish from './Publish';
import { S3Image } from '../../lib/s3';
import { withStyles } from '@material-ui/core/styles';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';

const CREATE_PARTIAL_SALE = gql`
  mutation createPartialSale(
    $numberOfClients: Int!,
    $assetsUnderManagement: Float!,
    $feeBasedRevenue: Float!,
    $trailerRevenue: Float!,
    $directRevenue: Float!,
    $commissionRevenue: Float!,
    $description: String!,
    $questions: [String!],
    $answers: [Boolean!],
    $states: [String!],
    $askingPrice: Float!,
    $segmentDetails: String!,
    $publishOnSave: Boolean!) {
    createPartialSale(numberOfClients: $numberOfClients, 
                      assetsUnderManagement: $assetsUnderManagement, 
                      feeBasedRevenue: $feeBasedRevenue, 
                      trailerRevenue: $trailerRevenue, 
                      directRevenue: $directRevenue, 
                      commissionRevenue: $commissionRevenue, 
                      description: $description, 
                      questions: $questions, 
                      answers: $answers,
                      states: $states, 
                      askingPrice: $askingPrice, 
                      segmentDetails: $segmentDetails, 
                      publishOnSave: $publishOnSave) {
      id
    }
  }
`;

const PartialSaleWizard = ({ partialSale, user, company, partialSaleQuestions, classes }) => {
  const [createPartialSale] = useMutation(CREATE_PARTIAL_SALE);
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formData, setFormData] = useState({
    numberOfClients: '',
    assetsUnderManagement: 0.0,
    feeBasedRevenue: 0.0,
    trailerRevenue: 0.0,
    directRevenue: 0.0,
    commissionRevenue: 0.0,
    description: "",
    states: [],
    questions: [],
    answers: [],
    askingPrice: '',
    segmentDetails: '',
    publishOnSave: true,
  });

  const steps = [
    {
      index: 0,
      label: 'Financial Details',
      component: () => <FinancialDetails formData={formData} handleChange={handleChange} user={user} company={company} />,
      validate: () => {
        const { assetsUnderManagement, feeBasedRevenue, trailerRevenue, directRevenue, commissionRevenue } = formData;
        return assetsUnderManagement + feeBasedRevenue + trailerRevenue + directRevenue + commissionRevenue > 0;
      },
    },
    {
      index: 1,
      label: 'Description',
      component: () => <Description formData={formData} handleChange={handleChange} />,
      validate: () => {
        const { description, segmentDetails } = formData;
        return Boolean(description) && Boolean(segmentDetails);
      },
    },
    {
      index: 2,
      label: 'Details',
      component: () => <Details 
        formData={formData} 
        handleCheckChange={handleQuestionChange} 
        handleMultiSelectChange={handleMultiSelectChange}
        partialSaleQuestions={partialSaleQuestions}
        company={company} />,
      validate: () => {
        const { cdClients, nextGenAdvisors, states } = formData;
        return true;
      },
    },
    {
      index: 3,
      label: 'Price Details',
      component: () => <PriceDetails formData={formData} handleCurrencyChange={handleCurrencyChange} />,
      validate: () => {
        const { askingPrice } = formData;
        return askingPrice > 0;
      },
    },
    {
      index: 4,
      label: 'Review',
      component: () => <Review formData={formData} />,
      validate: () => { return true },
    },
    {
      index: 5,
      label: 'Publish',
      component: () => <Publish formData={formData} handleCheckChange={handleCheckChange} />,
      validate: () => { return true; },
    }
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleCurrencyChange = (value, name) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleQuestionChange = (e) => {
    const { name, checked } = e.target;

    const answers = formData.answers;
    answers[name] = checked;
    setFormData({
      ...formData,
      answers: [ ...answers ]
    });
  };

  const handleCheckChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };

  const handleMultiSelectChange = (e) => {
    setFormData({
      ...formData,
      states: e.target.value
    });
  };

  const handleFinish = () => {
    createPartialSale({
      variables: {
        numberOfClients: parseInt(formData.numberOfClients),
        assetsUnderManagement: parseFloat(formData.assetsUnderManagement),
        feeBasedRevenue: parseFloat(formData.feeBasedRevenue),
        trailerRevenue: parseFloat(formData.trailerRevenue),
        directRevenue: parseFloat(formData.directRevenue),
        commissionRevenue: parseFloat(formData.commissionRevenue),
        description: formData.description,
        answers: formData.answers,
        questions: formData.questions,
        states: formData.states,
        askingPrice: parseFloat(formData.askingPrice),
        segmentDetails: formData.segmentDetails,
        publishOnSave: formData.publishOnSave
      }
    }).then(() => {
      setOpen(false);
      window.location.reload();
    });
  };

  const validateStep = () => {
    const stepData = steps.find(s => s.index === activeStep);
    return stepData ? !stepData.validate() : true;
  };

  const getStepContent = (step) => {
    const stepData = steps.find(s => s.index === step);
    return stepData ? stepData.component() : 'Unknown step';
  };

  if(formData.questions.length === 0 && partialSaleQuestions.length > 0) {
    setFormData({
      ...formData,
      questions: partialSaleQuestions.map(q => q.question) 
    });
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen} className={classes.button}>
        Create New {company.partial_sale_name}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogActions className={classes.actions}>
          <Button onClick={handleClose} color="secondary">
            Close
          </Button>
        </DialogActions>
        <CardMedia
          className={classes.media}
          image={S3Image('header-bg-9.png')}>
          <h2 className={classes.headerText}>
            Let's Create A {company.partial_sale_name}
          </h2>
        </CardMedia>
        <DialogContent>
          <TransitionGroup>
            <CSSTransition
              key={activeStep}
              timeout={500}
              classNames="step"
            >
              <Container>
                <Stepper activeStep={activeStep}>
                  {steps.map((step) => (
                    <Step key={step.index}>
                      <StepLabel>{step.label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length ? (
                  <Box>
                    <Typography variant="h6" gutterBottom>
                      All steps completed
                    </Typography>
                    <Button onClick={() => setActiveStep(0)}>Reset</Button>
                  </Box>
                ) : (
                  <Box>
                    {getStepContent(activeStep)}
                    <Box display="flex" justifyContent="space-between" mt={2}>
                      <Button disabled={activeStep === 0} onClick={handleBack}>
                        Back
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={validateStep()}
                        onClick={activeStep == steps.length - 1 ? handleFinish : handleNext}
                      >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    </Box>
                  </Box>
                )}
              </Container>
            </CSSTransition>
          </TransitionGroup>
        </DialogContent>

      </Dialog>
    </div>
  );
};

const styles = theme => ({
  media: {
    position: 'relative',
    height: '140px',
    display: 'flex',
    alignItems: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#00294980',
      zIndex: 1,
    },
  },
  headerText: {
    color: '#fff',
    margin: 0,
    paddingLeft: '40px',
    marginTop: '20px',
    zIndex: 2,
  },
  actions: {
    background: 'transparent',
    position: 'absolute',
    zIndex: 3,
    right: 0,
    color: '#fff',
  },
  button: {
    float: 'right',
    marginBottom: '20px',
  },
});

export default withStyles(styles)(PartialSaleWizard);
