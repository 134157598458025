import React from 'react';
import { Box, Typography } from '@material-ui/core';
import numeral from 'numeral';

const Review = ({ formData }) => (
  <Box>
    <Typography variant="h6" gutterBottom>
      Review Details
    </Typography>
    <Typography variant="body2">
      <b>Listing Title:</b> {formData.segmentDetails}
    </Typography>
    <Typography variant="body2">
      <b>Description:</b> {formData.description}
    </Typography>
    <hr/>
    <Typography variant="body2">
      <b>Number of Clients:</b> {formData.numberOfClients}
    </Typography>
    <Typography variant="body2">
      <b>Assets Under Management:</b> {numeral(formData.assetsUnderManagement).format('$0,0.00')}
    </Typography>
    <Typography variant="body2">
      <b>Fee Based Revenue:</b> {numeral(formData.feeBasedRevenue).format('$0,0.00')}
    </Typography>
    <Typography variant="body2">
      <b>Trailer Revenue:</b> {numeral(formData.trailerRevenue).format('$0,0.00')}
    </Typography>
    <Typography variant="body2">
      <b>Direct Revenue:</b> {numeral(formData.directRevenue).format('$0,0.00')}
    </Typography>
    <Typography variant="body2">
      <b>Recurring Revenue:</b> {numeral(formData.recurringRevenue).format('$0,0.00')}
    </Typography>
    <hr/>
    {formData.questions.map((question, index) => (
      <Typography variant="body2">
        <b>{question}:</b> {formData.answers[index] ? 'Yes' : 'No'}
      </Typography>
    ))  
    }
    <Typography variant="body2">
      <b>States:</b> {formData.states}
    </Typography>
    <hr/>
    <Typography variant="body2">
      <b>Asking Price:</b> {numeral(formData.askingPrice).format('$0,0.00')}
    </Typography>
    

  </Box>
);

export default Review;
