import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { findIndex, isEmpty } from 'lodash';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { FormattedMessage, injectIntl } from 'react-intl';

const questionOptions = (stylesheetName, intl) => {
  if (stylesheetName === 'rjpe') {
    return [
      intl.formatMessage({ id: 'dr_wizard.knockout_23' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_24' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_25' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_26' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_27' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_28' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_29' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_30' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_31' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_32' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_33' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_34' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_35' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_36' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_37' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_38' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_39' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_40' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_41' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_42' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_43' }),
      intl.formatMessage({ id: 'dr_wizard.knockout_44' }),
    ];
  }
  return [
    intl.formatMessage({ id: 'dr_wizard.knockout_1' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_2' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_3' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_3' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_4' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_5' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_6' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_7' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_8' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_9' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_10' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_11' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_12' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_13' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_14' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_15' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_16' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_17' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_18' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_19' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_20' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_21' }),
    intl.formatMessage({ id: 'dr_wizard.knockout_22' }),
  ];
};

class DealQuestion extends React.Component {
  state = { qType: null, addQuestion: true };

  typeByOptions = () => {
    const { value, stylesheetName, intl } = this.props;
    const { qType } = this.state;

    if (qType !== 'select' && findIndex(questionOptions(stylesheetName, intl), q => q === value) !== -1) {
      this.setState({ qType: 'select' });
    } else if (qType !== 'create' && value) {
      this.setState({ qType: 'create' });
    }
  };

  componentWillReceiveProps = nextProps => {
    const { stylesheetName, intl } = this.props;
    if (this.props.questions !== nextProps.questions) {
      if (questionOptions(stylesheetName, intl).includes(nextProps.value)) {
        this.setState({ qType: 'select' });
      } else if (!questionOptions(stylesheetName, intl).includes(nextProps.value)) {
        this.setState({ qType: 'create' });
      } else {
        this.setState({ qType: null });
      }
    }
  };

  sortQuestions = questions => {
    if (!isEmpty(questions)) {
      const keys = Object.keys(questions);
      const newObj = {};
      for (let i = 0; i < keys.length; i += 1) {
        newObj[`question_${i + 1}`] = questions[keys[i]];
      }
      return newObj;
    }
  };

  render () {
    const {
      questionId,
      value,
      updateQuestion,
      questions,
      classes,
      setButtonState,
      addQuestionState,
      deleteQuestion,
      stylesheetName,
      intl,
    } = this.props;
    const { qType } = this.state;
    if (!qType) {
      this.typeByOptions();
    }
    return (
      <FormControl className={classes.question}>
        <RadioGroup
          aria-label="Gender"
          name="gender1"
          value={qType}
          className={classes.radioGroup}
          onChange={e => this.setState({ qType: e.target.value })}>
          <FormControlLabel
            value="select"
            control={<Radio checked={qType === 'select'} />}
            label={`${intl.formatMessage({ id: 'dr_wizard.select_from_suggested' })}`}
          />
          <FormControlLabel
            value="create"
            control={<Radio checked={qType === 'create'} />}
            label={`${intl.formatMessage({ id: 'dr_wizard.create_my_own' })}`}
          />
        </RadioGroup>
        {qType === 'select' && (
          <div className={classes.selectRoot}>
            <Select
              onChange={e => updateQuestion({ questions: { ...questions, [questionId]: e.target.value } })}
              value={value || 'none'}
              className={classes.selectRoot}
              inputProps={{
                name : questionId,
                id   : `${questionId}-simple`,
              }}>
              <MenuItem value="none" disabled>
                <em>
                  <FormattedMessage id="dr_wizard.please_select_a_suggested" />
                </em>
              </MenuItem>
              {questionOptions(stylesheetName, intl).map(q => <MenuItem value={q}>{q}</MenuItem>)}
            </Select>
            <button
              type="button"
              style={{
                marginTop  : 10,
                padding    : 0,
                border     : 'none',
                background : 'none',
                fontSize   : 15,
                marginLeft : 30,
              }}
              onClick={() => {
                if (questions) {
                  deleteQuestion(questionId);
                  updateQuestion({ questions: this.sortQuestions(questions) });
                }
                this.setState({ qType: null, addQuestion: true }, setButtonState(true));
              }}>
              <i className="fas fa-trash-alt" />
            </button>
          </div>
        )}
        {qType === 'create' && (
          <div>
            <TextField
              fullWidth
              placeholder={`${intl.formatMessage({ id: 'dr_wizard.please_create_your_question' })}`}
              helperText={`${intl.formatMessage({ id: 'dr_wizard.256_characters' })}`}
              onChange={e =>
                updateQuestion({ questions: { ...questions, [questionId]: e.target.value.substr(0, 255) } })}
              value={value}
              key={questionId}
            />
            <button
              type="button"
              style={{
                marginTop  : 10,
                padding    : 0,
                border     : 'none',
                background : 'none',
                fontSize   : 15,
              }}
              onClick={() => {
                if (questions) {
                  deleteQuestion(questionId);
                  updateQuestion({ questions: this.sortQuestions(questions) });
                }
                this.setState({ qType: null, addQuestion: true }, setButtonState(true));
              }}>
              <i className="fas fa-trash-alt" />
            </button>
          </div>
        )}
      </FormControl>
    );
  }
}

DealQuestion.propTypes = {
  questionId       : PropTypes.string.isRequired,
  value            : PropTypes.string,
  updateQuestion   : PropTypes.func.isRequired,
  questions        : PropTypes.object.isRequired,
  classes          : PropTypes.object.isRequired,
  setButtonState   : PropTypes.object.isRequired,
  addQuestionState : PropTypes.bool.isRequired,
};

DealQuestion.defaultProps = {
  value : '',
};

const styles = {
  radioGroup          : {
    flexDirection : 'row',
  },
  formControl         : {
    position : 'relative',
  },
  question            : {
    marginBottom : 48,
  },
  selectRoot          : {
    textAlign : 'left',
  },
  button              : {
    backgroundColor : '#1396e2',
    color           : '#fff',
    maxWidth        : 250,
  },
  rjpeBackgroundColor : {
    backgroundColor : '#002949',
    '&:hover'       : {
      background : '#424949',
    },
  },
};

export default injectIntl(withStyles(styles)(DealQuestion));
