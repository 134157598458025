import React from 'react';
import { TextField, Box, Typography } from '@material-ui/core';
import RangeSlider from './RangeSlider';

const FinancialDetails = ({ formData, handleChange, user, company }) => (


  <Box>
    <Typography variant="h6" gutterBottom>
      Financial Details
    </Typography>
    <RangeSlider
      label="Number Of Clients"
      name="numberOfClients"
      object={formData}
      fieldName="numberOfClients"
      onChange={v => handleChange(v)}
      max={user.number_of_clients}
      step={1}
      prefix=''
    />
    <RangeSlider
      label="Assets Under Management"
      name="assetsUnderManagement"
      object={formData}
      fieldName="assetsUnderManagement"
      onChange={v => handleChange(v)}
      max={user.assets_under_management}
    />
    <RangeSlider
      label="Fee Based Revenue"
      name="feeBasedRevenue"
      object={formData}
      fieldName="feeBasedRevenue"
      onChange={v => handleChange(v)}
      max={user.fee_based_revenue}
    />
    <RangeSlider
      label="Trails Revenue"
      name="trailsRevenue"
      object={formData}
      fieldName="trailerRevenue"
      onChange={v => handleChange(v)}
      max={user.trailer_revenue}
    />
    { (company && !company.feature_types.includes('hide_direct_revenue')) && (
      <RangeSlider
        label="Direct Revenue"
        name="directRevenue"
        object={formData}
        fieldName="directRevenue"
        onChange={v => handleChange(v)}
        max={user.direct_revenue}
      />
    )}
    
    <RangeSlider
      label="Commision Revenue"
      name="commissionRevenue"
      object={formData}
      fieldName="commissionRevenue"
      onChange={v => handleChange(v)}
      max={user.transactional_revenue}
    />
  </Box>
);

export default FinancialDetails;
