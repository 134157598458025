import { connect } from 'react-redux';
import { forEach } from 'lodash';
import CompanyForm from '../components/CompanyForm/CompanyForm';
import * as actions from '../actions/companyActionCreators';

const generateCompanyPayload = company => {
  const payload = { company: {} };
  [
    'name',
    'host',
    'branded_name',
    'branded_name_fr',
    'display_name',
    'display_name_fr',
    'layout',
    'root_controller',
    'template',
    'contact_url',
    'help_url',
    'enterprise_redirect',
    'has_billing',
    'uses_enterprise_authorized_users',
    'mailchimp_list_id',
    'logout_destination',
    'administrator_email',
    'theme_name',
    'background_color',
    'primary_color',
    'secondary_color',
    'neutral_color',
    'accent_color',
    'intercom_token',
    'intercom_app_id',
    'intercom_api_key',
    'support_email',
    'go_live',
    'supported_locales',
    'business_type',
    'marketplace_type',
    'esignature_type',
    'country',
    'agent_word',
    'agent_word_fr',
    'ats_email',
    'concierge_link',
    'total_licensed_users',
    'seller_only_label',
    'valuation_rot_recurring_multiple',
    'valuation_rot_non_recurring_multiple',
    'valuation_dcf_payout_rate',
    'valuation_dcf_discount_rate',
    'valuation_dcf_has_book_tenure_discount',
    'valuation_dcf_has_turn_rate_discount',
    'valuation_ebitda_multiple_level_1',
    'valuation_ebitda_multiple_level_2',
    'valuation_ebitda_multiple_level_3',
    'valuation_ebitda_multiple_level_4',
    'valuation_ebitda_multiple_level_5',
    'valuation_ebitda_multiple_level_6',
    'valuation_ebitda_multiple_level_7',
    'valuation_ebitda_multiple_level_8',
    'valuation_ebitda_multiple_level_9',
    'valuation_ebitda_multiple_level_10',
    'valuation_ebitda_multiple_level_11',
    'valuation_ebitda_multiple_level_12',
    'valuation_ebitda_multiple_level_13',
    'valuation_ebitda_multiple_level_14',
    'terminal_growth_rate_expansion_stage',
    'terminal_growth_rate_decelerated_stage',
    'terminal_growth_rate_mature_stage',
    'esig_client_id',
    'esig_api_key',
    'esig_test_mode',
    'esig_allow_decline',
    'esig_nda_template_id',
    'esig_docusign_jwt_integration_key',
    'esig_docusign_authorization_server',
    'esig_docusign_aud',
    'esig_docusign_impersonated_user_guid',
    'esig_docusign_target_account_id',
    'esig_docusign_secret_key',
    'esig_docusign_nda_template_id',
    'esig_docusign_application_ping_url',
    'new_recruit_value',
    'match_pool_exception_email_list',
    'buyer_report_email_list',
    'seller_report_email_list',
    'recruitment_email',
    'company_valuation_disclaimer',
    'valuation_dcf_use_range',
    'valuation_dcf_range_low',
    'valuation_dcf_range_high',
    'valuation_rot_use_range',
    'valuation_rot_range_low',
    'valuation_rot_range_high',
    'valuation_ebitda_use_range',
    'valuation_ebitda_low',
    'valuation_ebitda_high',
    'recruitment_team_word',
    'recruitment_agent_word',
    'student_word',
    'student_program_title',
    'recruitment_organization_word',
    'affiliate_name',
    'stylesheet_name',
    'branch_exceptions',
    'id',
    'sftp_path',
    'sftp_csv_url',
    'sftp_plan_file_url',
    'about',
    'contact_copy',
    'contact_email',
    'deal_room_branded_name',
    'deal_room_single_word',
    'deal_room_branded_name_fr',
    'deal_room_single_word_fr',
    'email_footer_banner',
    'user_name_prefix',
    'nda_template_preview_pdf_url',
    'succession_guide_documentation',
    'succession_guide_thumbnail_image_url',
    'succession_guide_leaderboard_image_url',
    'buyer_guide_thumbnail_image_url',
    'buyer_guide_leaderboard_image_url',
    'buyer_guide_documentation',
    'default_sender_email_address',
    'default_continuity_builder_email_address',
    'default_deal_email_address',
    'meeting_room_suggestion_threashold',
    'affiliate_display_name',
    'copyright_notice',
    'viewed_profile_mailer_date_range',
    'viewed_profile_mailer_send_dates',
    'new_users_in_proximity_mailer_date_range',
    'new_users_in_proximity_mailer_send_dates',
    'pipeline_card_default_value_type',
    'pipeline_card_default_value_source',
    'generic_mailer',
    'generic_mailer_non_cta',
    'grey_mailer',
    'docusign_rsa_key',
    'catastrophic_planning_word',
    'catastrophic_planning_word_fr',
    'insurance_commissions_information_tooltip',
    'trails_word',
    'cash_flow_analysis_word',
    'cash_flow_analysis_word_fr',
    'file_import_disclaimer',
    'valuation_disclaimer_en',
    'valuation_disclaimer_fr',
    'contact_copy_fr',
    'copyright_notice_fr',
    'about_fr',
    'cash_flow_financing_interest_rate',
    'chat_prompt_seconds_delay',
    'automatically_calibrate_impact_on',
    'featured_support_summary_short_copy',
    'featured_support_summary_long_copy',
    'featured_support_summary_team_name',
    'featured_support_summary_short_copy_fr',
    'featured_support_summary_long_copy_fr',
    'featured_support_summary_team_name_fr',
    'featured_education_content_1_url',
    'featured_education_content_1_thumbnail_url',
    'featured_education_content_1_title',
    'featured_education_content_2_url',
    'featured_education_content_2_thumbnail_url',
    'featured_education_content_2_title',
    'featured_education_content_3_url',
    'featured_education_content_3_thumbnail_url',
    'featured_education_content_3_title',
    'sign_up_page_compliance_copy',
    'cashflow_payout_word',
    'proof_point_media_type',
    'proof_point_envelope_to',
    'proof_point_match_regex',
    'proof_point_findbob_bcc_email',
    'proof_point_increment',
    'proof_point_increment_unit',
    'process_proof_point_messages',
    'process_proof_point_intercom',
    'proof_point_intercom_company_id',
    'loan_prime_rate_plus_minus',
    'loan_rate_use_override',
    'loan_rate_override',
    'dcf_prime_rate_plus_minus',
    'dcf_rate_use_override',
    'dcf_rate_override',
    'cash_flow_financing_prime_rate_plus_minus',
    'cash_flow_financing_rate_use_override',
    'cash_flow_financing_rate_override',
    'rjas_benchmark_expense_percentage',
    'admin_home_office_scheduling_link',
    'partial_sale_name',
    
  ].map(name => (payload.company[name] = company[name]));

  return payload;
};

const mapStateToProps = state => ({
  company                : state.company,
  generateCompanyPayload,
});

export default connect(mapStateToProps, actions)(CompanyForm);
