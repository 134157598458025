import React, { useState } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { debounce, clamp, get, range } from 'lodash';
import { withStyles } from "@material-ui/core/styles";
import * as cfaActionCreators from '../../actions/cfaActionCreators';
import SaveCfaButton from "./SaveCfaButton";
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import CardHeader from '@material-ui/core/CardHeader';
import Paper from '@material-ui/core/Paper';
import { Grid } from '@material-ui/core';
import CfaSummary from "./CfaSummary";
import { FormattedMessage, injectIntl } from "react-intl";
import NumberFormat from "react-number-format";
import EditSellerNote from './EditSellerNote';
import CloseIcon from '@material-ui/icons/Close';
import NameAndPrice from './CfaEdit/NameAndPrice';
import TaxRateAssumptions from './CfaEdit/TaxRateAssumptions';
import LumpSumPayment from './CfaEdit/LumpSumPayment';
import CheckSwap from './CfaEdit/CheckSwap';
import FixedPayments from './CfaEdit/FixedPayments';
import ContinuingPayments from './CfaEdit/ContinuingPayments';
import ConsultingAgreements from './CfaEdit/ConsultingAgreements';
import AdvancedInfo from './CfaEdit/AdvancedInfo';
import PracticeMetrics from './CfaEdit/PracticeMetrics';
import DealStructure from './CfaEdit/DealStructure';

const NumberFormatCustom = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          }
        });
      }}
      thousandSeparator>
    </NumberFormat>
  )
}


const EditCfa = ({ list, query, classes, updateCfaQuery, intl, company }) => {
  const [editing, setEditing] = useState(false);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };


  const showSellerNote = get(company, 'features', []).includes('cashflow_seller_note');

  let valid = true;
  return <React.Fragment>
    <Button variant="contained" className="right cfa_edit" onClick={() => {
      setEditing(!editing);
      setDrawerOpen(open => !open);
    }}>
      {editing ? intl.formatMessage({ id: 'calculators.cfa.stop_editing' }) : intl.formatMessage({ id: 'calculators.cfa.edit' })}
    </Button>


    <Drawer anchor='bottom' open={drawerOpen} onClose={() => toggleDrawer(open => false)}>
      <div className={classes.drawer}>
        <Button variant="contained" className={`right cfa_edit ${classes.closeButton}`} onClick={() => {
          setEditing(!editing);
          setDrawerOpen(open => !open);
        }}>
          <CloseIcon />
        </Button>
        <div className={classes.spacer}>
          <CardHeader title="Cash Flow Calculator" />
        </div>
        <Paper className={classes.paper}>
          <Grid container className={classes.root} spacing={1}>
            <Grid item xs={12}>
              <NameAndPrice classes={classes} updateCfaQuery={updateCfaQuery} />
            </Grid>

            <div className={classes.header}>Practice Metrics</div>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <PracticeMetrics classes={classes} updateCfaQuery={updateCfaQuery} />
            </Grid>
            <TaxRateAssumptions classes={classes} updateCfaQuery={updateCfaQuery} />
            <Grid item xs={12}></Grid>
            <Grid item>
              <DealStructure classes={classes} updateCfaQuery={updateCfaQuery} />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item>
              <LumpSumPayment classes={classes} updateCfaQuery={updateCfaQuery} />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item>
              <EditSellerNote
                classes={classes}
                query={query}
                updateCfaQuery={updateCfaQuery}
                showSellerNote={showSellerNote}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item>
              <ContinuingPayments classes={classes} updateCfaQuery={updateCfaQuery} />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <FixedPayments classes={classes} updateCfaQuery={updateCfaQuery} />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item>
              <ConsultingAgreements classes={classes} updateCfaQuery={updateCfaQuery} />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item>
              <CheckSwap classes={classes} updateCfaQuery={updateCfaQuery} />
            </Grid>
            {valid ? <SaveCfaButton /> : <Button disabled variant="contained">Save</Button>}
          </Grid>
        </Paper>
      </div>
    </Drawer>
  </React.Fragment >;
};

EditCfa.propTypes = {
  query: PropTypes.array,
  classes: PropTypes.object.isRequired,
};

EditCfa.defaultProps = {
  list: [],
}

const styles = () => ({
  sub_heading: {
    fontWeight: 'normal',
    // center the text vertically
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    lineHeight: '4',
    marginRight: '10px',
  },
  drawer: {
    maxHeight: '800px',
    padding: 20,
  },
  closeButton: {
    position: 'absolute',
    right: '10px',
    top: '10px',
  },
  spacer: {
    height: '58px',
    marginTop: '-26px',
    color: 'white',
    background: '#002949',
    marginLeft: '-20px',
    marginRight: '-28px',
    paddingLeft: '10px',
  },
  paper: {
    padding: '18px',
    margin: '6px',
  },
  header: {
    fontSize: '20px',
    fontWeight: '100',
  },
  percentage: {
    width: '75px',
    marginLeft: '10px',
  },
  currency: {
    width: '120px !important',
    marginLeft: '10px',
  },
  radioGroup: {
    paddingTop: 8,
    float: 'right',
  },
});

const mapStateToProps = (state, props) => {
  return {
    result: { ...get(state, 'cfa.result', {}) },
    query: { ...get(state, 'cfa.query', {}) },
    company: get(state, 'company', {}),
  };
};

export default connect(mapStateToProps, cfaActionCreators)(withStyles(styles)(injectIntl(EditCfa)));


