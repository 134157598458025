import React from "react";
import PropTypes, { number } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Collapse from "@material-ui/core/Collapse";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { MoreVert } from "@material-ui/icons";
import Show from "./Show";
import Edit from "./Edit";
import PartialSaleStatusToggle from "./PartialSaleStatusToggle";
import { replace, startCase, toLower } from "lodash";
import numeral from "numeral";



const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

const PartialSaleListing = (props) => {
  const [partialSale, setPartialSale] = React.useState(props.partialSale);
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useRowStyles();
  const numberOfInterests = partialSale.partial_sale_listing_users.length;


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleEdit = () => {
    setEdit(!edit);
    handleClose();
  };

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <PartialSaleStatusToggle partialSale={partialSale} setPartialSale={setPartialSale} />
        </TableCell>
        <TableCell component="th" scope="row">
          {partialSale.segment_details}
        </TableCell>
        <TableCell align="right">{partialSale.views_count}</TableCell>
        <TableCell align="right">{numberOfInterests}</TableCell>
        <TableCell align="right">
          {(edit || open) && <>
            <IconButton
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVert />
            </IconButton>

            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {edit ? <MenuItem onClick={toggleEdit}>Stop Editing</MenuItem> : <MenuItem onClick={toggleEdit}>Edit</MenuItem>}
            </Menu>
          </>}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              {edit ? (
                <Edit partialSale={partialSale} setPartialSale={setPartialSale} />
              ) : (
                <Show partialSale={partialSale} />
              )}
              {numberOfInterests > 0 && (
                <>
                  <Typography variant="h6" gutterBottom component="div">
                    Interests
                  </Typography>

                  <Table size="small" aria-label="purchases">
                    <TableHead>
                    </TableHead>
                    <TableBody>
                      {partialSale.partial_sale_listing_users.map((listingUser) => (
                        <>
                          <TableRow key={listingUser.friendly_name}>
                            <TableCell component="th" scope="row">
                              {listingUser.buyer.user_name}
                            </TableCell>
                            <TableCell>{listingUser.buyer.city}, {listingUser.buyer.province}</TableCell>
                            <TableCell align="right">{numeral(listingUser.buyer.assets_under_management).format('$0,0')} AUM</TableCell>
                            <TableCell align="right">{startCase(toLower(replace(listingUser.status, /_/g, ' ')))}</TableCell>

                            <TableCell align="right">
                              <form
                                action="/messages/create_chat_from_partial_sale_user"
                                method="post"
                              >
                                <input type="hidden" name="friendly_id" value={listingUser.friendly_name} />
                                <input type="hidden" name="authenticity_token" value={ReactOnRails.authenticityToken()} />
                                <button
                                  class="chat-icon ml-auto btn-transparent"
                                  type="submit">
                                    {listingUser.status !== 'expressed_interest' ? (
                                      <i class="fas fa-envelope"></i>
                                    ) : (
                                      "Approve"
                                    )}
                                </button>
                              </form>
                            </TableCell>
                          </TableRow>
                          {listingUser.status === 'expressed_interest' && (
                            <TableRow style={{border: '0'}}>
                              <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                                <h4>Request Message</h4>
                                {listingUser.request_message}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </>
              )}
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

PartialSaleListing.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

export default PartialSaleListing;